import request, { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import { apiBase } from "../../constants";
import { IFlow } from "../../components/Flows/FlowRoute";
import { ILink } from "../../components/FlowEditor/Link/Link";
import { unserializeSteps } from "../../domain/helpers/unserializeSteps";
import { IntegrationCreateUpdate } from "encharge-domain/lib/definitions/api/IntegrationCreateUpdate";
import { handleForbiddenRead } from "./handleForbidden";
import { ISegmentV2 } from "encharge-domain/definitions/ambient/segment";
import { endUsersObjectType } from "encharge-domain/lib/helpers/sync_engine_helper";
import moment from "moment";

export type FullFlow = Overwrite<
  IIntegration,
  { steps: (IIntegrationStep & { service: IService })[] }
> & {
  links: ILink[];
  objectType: string;
};

const unserializeFlow = async (integration: FullFlow) => {
  const flow: IFlow = {
    id: integration.id,
    name: integration.name,
    status: integration.status,
    steps: await unserializeSteps(integration.steps),
    links: integration.links,
    decorations: integration.decorations,
    createdAt: integration.createdAt,
    updatedAt: integration.updatedAt,
    recipe: integration.recipe,
    objectType: integration.data.objectType || endUsersObjectType,
    aiGenerated: integration.data.aiGenerated,
    canEnterOnce: integration.data.canEnterOnce,
    canEnterOncePerPeriod: integration.data.canEnterOncePerPeriod,
  };
  return flow;
};

export const getFlows = async (limit = 50, offset = 0): Promise<IFlow[]> => {
  try {
    const res: AxiosResponse<any> = await request({
      url: `${apiBase}/v1/integrations/`,
      params: {
        limit,
        offset,
      },
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    const integrations = res.data.integrations;
    // Unserialize all integrations' steps
    const flows = await Promise.all(_.map(integrations, unserializeFlow));

    if (flows.length >= limit) {
      // We have more integrations than limit, so we need to fetch them all
      const nextFlows = await getFlows(limit, offset + limit);
      return [...flows, ...nextFlows];
    }
    return flows;
  } catch (e) {
    if (handleForbiddenRead((e as AxiosError).response)) return [];
    // If we have a specific error message from server throw it.
    const msg =
      ((e as AxiosError)?.response?.data as any)?.error?.message ||
      (e as AxiosError).message ||
      "We couldn't retrieve your flows. ";
    throw new Error(msg);
  }
};
export const getFlowsNames = async () => {
  try {
    const res: AxiosResponse<any> = await request({
      url: `${apiBase}/v1/integrations/names`,
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    const integrations = res.data.integrations;
    return integrations;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      ((e as AxiosError)?.response?.data as any)?.error?.message ||
      (e as AxiosError).message ||
      "We couldn't retrieve your flows. ";
    throw new Error(msg);
  }
};

export const getFlow = async (flowId: IFlow["id"], readOnlyAuth?: string) => {
  // Load flow
  let res: AxiosResponse<any>;
  try {
    res = await request({
      url: `${apiBase}/v1/integrations/${flowId}`,
      method: "GET",
      params: {
        "read-only-auth": readOnlyAuth,
      },
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      ((e as AxiosError)?.response?.data as any)?.error?.message ||
      (e as AxiosError).message ||
      "We couldn't retrieve your flow.";
    throw new Error(msg);
  }
  const jsonBody = res.data;
  const integration = jsonBody.integration as FullFlow;

  const flow = await unserializeFlow(integration);

  return [flow];
};

export const getFlowHistory = async (
  flowId: IFlow["id"],
  cursor?: string,
  stepId?: number,
  limit: number = 100
) => {
  // Load flow
  try {
    const res: AxiosResponse<any> = await request({
      url: `${apiBase}/v1/integrations/${flowId}/history?limit=${limit}&cursor=${
        cursor || ""
      }&stepId=${stepId || ""}`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });

    const jsonBody = res.data;
    return jsonBody;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      ((e as AxiosError)?.response?.data as any)?.error?.message ||
      (e as AxiosError).message ||
      "We couldn't retrieve your flow.";
    throw new Error(msg);
  }
};

export const getFlowMetricsCount = async (flowId: IFlow["id"]) => {
  // Load flow's steps' people's counts
  let res: AxiosResponse<{
    integration: {
      steps: {
        id: IIntegrationStep["id"];
        data: {
          peopleCounts: IIntegrationStep["data"]["peopleCounts"];
        };
      }[];
    };
  }>;
  try {
    res = await request({
      url: `${apiBase}/v1/integrations/${flowId}/people-counts`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      ((e as AxiosError)?.response?.data as any)?.error?.message ||
      (e as AxiosError).message ||
      "We couldn't retrieve your flow metrics.";
    throw new Error(msg);
  }
  return res.data.integration;
};

export const getFlowsStepPeopleCount = async (
  flowIds: number[],
  days: number
) => {
  try {
    const res: AxiosResponse<{
      metrics: Dictionary<{
        [flowId: number]: {
          peopleReachedGoal: number;
          peopleEnteredFlow: number;
        };
      }>;
    }> = await request({
      url: `${apiBase}/v1/integrations/people-counts?days=${days}&flowIds=${flowIds}`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.metrics;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      ((e as AxiosError)?.response?.data as any)?.error?.message ||
      (e as AxiosError).message ||
      "We couldn't retrieve your flow metrics.";
    throw new Error(msg);
  }
};

/**
 * Retrieve unique people counts for flows, by date.
 */
export const getFlowsUniqueMetrics = async ({
  flowIds,
  startDate,
  endDate,
  metricsPeriod,
  forceRefresh,
}: {
  flowIds: number[];
  startDate: Date;
  endDate: Date;
  metricsPeriod: string;
  forceRefresh?: boolean;
}) => {
  try {
    const res: AxiosResponse<{
      pending: boolean;
      computeStartTime?: string;
      metrics: {
        [flowId: string]: {
          [date: string]: {
            peopleReachedGoal: number;
            peopleEnteredFlow: number;
          };
        };
      };
    }> = await request({
      url: `${apiBase}/v1/integrations/people-counts-unique`,
      params: {
        flowIds: flowIds.join(","),
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        period: metricsPeriod,
        forceRefresh,
      },
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      ((e as AxiosError)?.response?.data as any)?.error?.message ||
      (e as AxiosError).message ||
      "We couldn't retrieve your flow metrics.";
    throw new Error(msg);
  }
};

export const createFlow = async (flow: IntegrationCreateUpdate) => {
  let res: AxiosResponse<{ integration: FullFlow }>;
  try {
    res = await request({
      url: `${apiBase}/v1/integrations/`,
      method: "POST",
      data: flow,
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      ((e as AxiosError)?.response?.data as any)?.error?.message ||
      (e as AxiosError).message ||
      "We couldn't create your flow.";
    throw new Error(msg);
  }
  const newFlow = res.data.integration;
  const steps = await unserializeSteps(newFlow.steps);
  return _.assign(newFlow, { steps, objectType: newFlow.data.objectType });
};

export const createFlowFromExisting = async ({
  existingFlowId,
  recipeId,
  readOnlyAuth,
  targetAccountId,
}: {
  existingFlowId: IIntegration["id"];
  recipeId?: IRecipe["id"];
  readOnlyAuth?: string;
  targetAccountId?: IAccount["id"];
}) => {
  let res: AxiosResponse<{ integration: FullFlow }>;
  try {
    const optionalParams = {} as any;
    if (readOnlyAuth) {
      optionalParams["read-only-auth"] = readOnlyAuth;
    }
    if (targetAccountId) {
      optionalParams["target-account"] = targetAccountId;
    }
    res = await request({
      url: `${apiBase}/v1/integrations`,
      params: {
        source: existingFlowId,
        recipe: recipeId,
        ...optionalParams,
      },
      method: "POST",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      ((e as AxiosError)?.response?.data as any)?.error?.message ||
      (e as AxiosError).message ||
      "We couldn't create your flow.";
    throw new Error(msg);
  }
  const newFlow = res.data.integration;
  const steps = await unserializeSteps(newFlow.steps);
  return _.assign(newFlow, { steps, objectType: newFlow.data.objectType });
};

export const updateFlow = async (flow: IFlow & { archived?: true }) => {
  let res: AxiosResponse<{ integration: IFlow }>;
  const integration: IntegrationCreateUpdate = {
    id: flow.id,
    name: flow.name,
    status: flow.status,
    data: {
      canEnterOnce: flow.canEnterOnce,
      canEnterOncePerPeriod: flow.canEnterOncePerPeriod,
    },
  };
  if (flow.archived) {
    integration.archived = flow.archived;
  }
  try {
    res = await request({
      url: `${apiBase}/v1/integrations/${integration.id}`,
      data: integration,
      method: "PATCH",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      ((e as AxiosError)?.response?.data as any)?.error?.message ||
      (e as AxiosError).message ||
      "We couldn't update your flow.";
    throw new Error(msg);
  }
  return res.data.integration;
};

export const getShareToken = async (flowId: IFlow["id"]) => {
  try {
    const res: AxiosResponse<{ shareToken: string }> = await request({
      url: `${apiBase}/v1/integrations/${flowId}/share-token`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data?.shareToken;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      ((e as AxiosError)?.response?.data as any)?.error?.message ||
      (e as AxiosError).message ||
      "Couldn't prepare flow for sharing.";
    throw new Error(msg);
  }
};

export const getEmailsForReadOnlyFlow = async (
  flowId: IFlow["id"],
  readOnlyAuth: string
) => {
  try {
    const res: AxiosResponse<{ emails: IEmailContent[] }> = await request({
      url: `${apiBase}/v1/integrations/${flowId}/emails`,
      method: "GET",
      params: {
        "read-only-auth": readOnlyAuth,
      },
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.emails;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      ((e as AxiosError)?.response?.data as any)?.error?.message ||
      (e as AxiosError).message ||
      "We couldn't retrieve emails. ";
    throw new Error(msg);
  }
};

export const getSegmentsForReadOnlyFlow = async (
  flowId: IFlow["id"],
  readOnlyAuth: string
) => {
  try {
    const res: AxiosResponse<{ segments: ISegmentV2[] }> = await request({
      url: `${apiBase}/v1/integrations/${flowId}/segments`,
      method: "GET",
      params: {
        "read-only-auth": readOnlyAuth,
      },
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.segments;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      ((e as AxiosError)?.response?.data as any)?.error?.message ||
      (e as AxiosError).message ||
      "We couldn't retrieve segments. ";
    throw new Error(msg);
  }
};

export const getFlowAttachedResources = async (
  flowId: IFlow["id"],
  readOnlyAuth?: string
) => {
  try {
    const res: AxiosResponse<{
      email: number[];
      segment: number[];
      field: string[];
    }> = await request({
      url: `${apiBase}/v1/integrations/${flowId}/attached`,
      method: "GET",
      params: {
        "read-only-auth": readOnlyAuth,
      },
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      ((e as AxiosError)?.response?.data as any)?.error?.message ||
      (e as AxiosError).message ||
      "We couldn't retrieve flow resources. ";
    throw new Error(msg);
  }
};

export const removeObjectFromFlow = async ({
  flowId,
  objectId,
}: {
  flowId: IFlow["id"];
  objectId: number | string;
}) => {
  try {
    const res: AxiosResponse = await request({
      url: `${apiBase}/v1/integrations/${flowId}/end/${objectId}`,
      method: "DELETE",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      ((e as AxiosError)?.response?.data as any)?.error?.message ||
      (e as AxiosError).message ||
      "Couldn't end flow. ";
    throw new Error(msg);
  }
};

export const removeObjectFromStep = async ({
  stepId,
  objectId,
}: {
  stepId: IIntegrationStep["id"];
  objectId: number | string;
}) => {
  try {
    const res: AxiosResponse = await request({
      url: `${apiBase}/v1/steps/${stepId}/objects/${objectId}`,
      method: "DELETE",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      ((e as AxiosError)?.response?.data as any)?.error?.message ||
      (e as AxiosError).message ||
      "Couldn't remove from step. ";
    throw new Error(msg);
  }
};

export const skipWaitStep = async ({
  stepId,
  objectId,
}: {
  stepId: IIntegrationStep["id"];
  objectId: number | string;
}) => {
  try {
    const res: AxiosResponse = await request({
      url: `${apiBase}/v1/steps/${stepId}/objects/${objectId}/skip`,
      method: "POST",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      ((e as AxiosError)?.response?.data as any)?.error?.message ||
      (e as AxiosError).message ||
      "Couldn't skip wait step. ";
    throw new Error(msg);
  }
};

export const getAIGeneratedFlows = async () => {
  try {
    const res: AxiosResponse<{
      integrations: {
        id: number;
        name: string;
        aiGeneratedEmailText: string;
      }[];
    }> = await request({
      url: `${apiBase}/v1/integrations/ai-generated`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.integrations;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg = "We couldn't retrieve your AI generated flows. ";
    throw new Error(msg);
  }
};
